export const LINKS = {
	"WhatsApp": {
		"Link": "https://wa.me/+919400459911",
		"Label": "9400459911"
	}
}

export const CONTACTS = {
	"MobileNumber": "9400459911"
}

export const SOCIAL = {
	"Facebook": {
		"Link": "",
		"Label": ""
	},
	"Instagram": {
		"Link": "",
		"Label": ""
	}
}